import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { StaticImage } from "gatsby-plugin-image"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import { useBreakpoint } from "gatsby-plugin-breakpoints"


const Service = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const breakPoints = useBreakpoint();
  let position

  if(breakPoints.md) {
    position = ( {position: "absolute"} )
  }else{
    position = ( {position: "relative"} )
  }

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="サービス｜エンジニアリング・監視保守・デザイン｜株式会社スタートアッププラス" />

      <div className="block xl:flex justify-between">

        <div id="pageTitle" className="relative xl:fixed w-full xl:w-48 h-auto xl:h-full text-white bg-servicePage bg-no-repeat bg-center bg-cover  z-20 ">
          <h1 className="font-Copperplate text-center text-2xl sm:text-3xl py-10 ">
            Service
          </h1>
          <ul className="w-full text-center pb-5">
            <li className='mb-5'>
              <AnchorLink to="#Engineering" className=''>
                エンジニアリング
              </AnchorLink>
            </li>
            <li className='mb-5'>
              <AnchorLink to="#Monitoring" className=''>
                監視・保守
              </AnchorLink>
            </li>
          </ul>
        </div>

        <div id="main" className="static xl:ml-48 w-full">
          <div className=" w-11/12 max-w-[1000px] mx-auto py-10 sm:py-10">

          <section id="PageDescription" className="relative w-full xl:w-5/6 mb-20">
            <p className="py-16 pl-10 pr-10 md:pr-32 text-xl sm:text-2xl w-full md:w-2/3 bg-key-sand">
            スタートアッププラスは、知識・経験・技術力を活かした付加価値の高いサービスを提供します
            </p>
            <StaticImage 
              src="../images/service-top.png" 
              className=" -bottom-12 right-0 w-full md:w-[350px]"
              style={position} />
          </section>

          <section id="Engineering" className="mb-20">
            <h2 className="text-3xl md:text-4xl font-Copperplate border-b border-key-sand my-5 py-3 ">
              Engineering
            </h2>
            <div className="w-full md:flex justify-between py-5">
              <StaticImage src="../images/EngineeringImg.png" 
                className=" w-4/5 md:w-2/5 max-w-[350px] my-5 mr-5"/>
              <div className=" w-full md:w-3/5 px-5">
                <h3 className=" text-lg md:text-2xl border-l-[5px] border-key-sand mt-10 mb-10 py-2 pl-3">
                  システムをデザインする
                </h3>
                <p className="text-base md:text-xl">
                スタートアッププラスは、多くの経験・ノウハウを基に、クライアントの投資価値を最大化できるシステム開発を目指し、システム要望に適したＱＣＤ※を満たすシステム構成をご提案致します。<br />
                　<br />
                インフラ、ミドルウェアの設計構築を始め、アプリケーション開発の各レイヤで、常にシステムライフサイクル全体を見通した最適なアウトプットをご提供致します。<br />
                　<br />
                <small>※クオリティ・コスト・デリバリー</small>
                </p>
              </div>
            </div>
          </section>

          <section id="Monitoring" className="mb-20">
            <h2 className="text-3xl md:text-4xl font-Copperplate border-b border-key-sand my-5 py-3 ">
              Monitoring & Maintenance
            </h2>
            <h3 className="text-lg md:text-2xl border-l-[5px] border-key-sand mt-10 mb-2 py-2 pl-3">
              システムを保守し継続的に運用する<br />あらゆるサポートを実施
            </h3>
            <p className="p-5 mb-5 text-base md:text-xl md:leading-relaxed">
            仕組み・システムは作って終わりではありません。<br />
            安定的に稼働させ、さらには適用範囲の拡大や、効率の向上を目指していくもの。<br />
            弊社のご提供する監視・運用サービスは、単にオペレーティング対応を行うだけではなく、サービス基盤のモニタリングと課題の抽出、改善提案から改善アクションまでのサイクルを継続的に行い、成長を続けるクライアントのサービス基盤を安定的に支えていきます。
            </p>
            <div className="flex flex-wrap justify-around">
              <div className="w-72 m-2">
                <div className="flex items-center">
                  <StaticImage src="../images/serviceIcon1.png" className=" w-16"/>
                  <h4 className=" text-xl ml-4 ">システム運営監視</h4>
                </div>
                <p className="my-5 mx-4">
                24時間365日体制でお客様のシステムを監視します。障害検知時にはあらかじめ決められたフローに基づきメール・電話等にてご連絡いたします。
                </p>
              </div>
              <div className="w-72 m-2">
                <div className="flex items-center">
                  <StaticImage src="../images/serviceIcon2.png" className=" w-16"/>
                  <h4 className=" text-xl ml-4 ">システム障害対応</h4>
                </div>
                <p className="my-5 mx-4">
                障害時のサービス復旧対応、及び障害の切り分け作業を実施します。フルサービスをご契約のお客様には障害原因調査、及び恒久対策のご提案を行います。
                </p>
              </div>
              <div className="w-72 m-2">
                <div className="flex items-center">
                  <StaticImage src="../images/serviceIcon3.png" className=" w-16"/>
                  <h4 className=" text-xl ml-4 ">システム定常運用</h4>
                </div>
                <p className="my-5 mx-4">
                手順に基づく定常的なシステムの運用作業を実施します。サーバ・ミドルウェアの再起動、カレンダー登録、特定ファイルの転送など、ご要望に併せて対応いたします。
                </p>
              </div>
              <div className="w-72 m-2">
                <div className="flex items-center">
                  <StaticImage src="../images/serviceIcon4.png" className=" w-16"/>
                  <h4 className=" text-xl ml-4 ">クラウド導入サービス<br />　　　　for AWS</h4>
                </div>
                <p className="my-5 mx-4">
                  お客様に最適なAWSソリューションをご提案いたします。導入時の環境構築、データ移行、管理者さまやご利用のお客さま向けの運用設計などをご支援させていただきます。
                </p>
              </div>
              <div className="w-72 m-2">
                <div className="flex items-center">
                  <StaticImage src="../images/serviceIcon5.png" className=" w-16"/>
                  <h4 className=" text-xl ml-4 ">ヘルプデスク</h4>
                </div>
                <p className="my-5 mx-4">
                ITサポートに関わる相談窓口をご提供いたします。人材が確保できない企業様向けのヘルプデスク窓口から専門スキルを有するSEのサポートまで対応可能です。
                </p>
              </div>
              <div className="w-72 m-2">
                <div className="flex items-center">
                  <StaticImage src="../images/serviceIcon6.png" className=" w-16"/>
                  <h4 className=" text-xl ml-4 ">構成・<br />インシデント管理</h4>
                </div>
                <p className="my-5 mx-4">
                システム構成の変更管理、ドキュメントの保守、インシデント（障害・運用・課題）の管理を実施します。管理対象・利用ツールなどは柔軟に対応いたします。
                </p>
              </div>
              <div className="w-72 m-2">
                <div className="flex items-center">
                  <StaticImage src="../images/serviceIcon7.png" className=" w-16"/>
                  <h4 className=" text-xl ml-4 ">構成変更作業</h4>
                </div>
                <p className="my-5 mx-4">
                お客様のご要望・セキュリティ対策などの要件において、設定の追加変更作業を実施します。設定の検証や手順作成などもシステム構成に応じて対応いたします。
                </p>
              </div>
              <div className="w-72 m-2">
                <div className="flex items-center">
                  <StaticImage src="../images/serviceIcon8.png" className=" w-16"/>
                  <h4 className=" text-xl ml-4 ">レポート</h4>
                </div>
                <p className="my-5 mx-4">
                システムの稼働状況、各種インシデントのステータスなど、システム稼働レポートを作成し、ご提供いたします。ご訪問しての報告会形式でのレポートも対応可能です。
                </p>
              </div>
              <div className="w-72 m-2">
                <div className="flex items-center">
                  <StaticImage src="../images/serviceIcon9.png" className=" w-16"/>
                  <h4 className=" text-xl ml-4 ">その他オプション</h4>
                </div>
                <p className="my-5 mx-4">
                以上の他にも、お客様のご要望・環境に合わせて、柔軟な運用サポートをご提供いたします。様々な環境に対し、最適なサービスを組み合わせてご提案いたします。
                </p>
              </div>

            </div>
          </section>
          </div>
        </div>
      </div>

    </Layout>
  )
}

export default Service

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
